import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kennslan',
  templateUrl: './kennslan.component.html',
  styleUrls: ['./kennslan.component.css']
})
export class KennslanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
