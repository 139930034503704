<div class="fullwidth">
  <div class="verd container">
    <h1>
      Verðskrá
    </h1>
    <div class="topptexti mt-2">
      Einkakennsla
    </div>
    <div class="row tafla">
      <div class="col-md-4 col-2"></div>
      <div class="col-md-2 col-2">Einn</div>
      <div class="col-md-2 col-2">Tveir</div>
      <div class="col-md-2 col-2">Þrír</div>
      <div class="col-md-2 col-2">Fleiri en þrír</div>
    </div>
    <hr>
    <div class="row tafla">
      <div class="col-md-4 col-2">1 klst</div>
      <div class="col-md-2 col-2">9.000 kr</div>
      <div class="col-md-2 col-2">11.000 kr</div>
      <div class="col-md-2 col-2">12.500 kr</div>
      <div class="col-md-2 col-2">14.000 kr</div>
    </div>
    <div class="row tafla">
      <div class="col-md-4 col-2">2 klst</div>
      <div class="col-md-2 col-2">18.000 kr</div>
      <div class="col-md-2 col-2">22.000 kr</div>
      <div class="col-md-2 col-2">25.000 kr</div>
      <div class="col-md-2 col-2">28.000 kr</div>
    </div>
    <div class="row tafla">
      <div class="col-md-4 col-2">3 klst</div>
      <div class="col-md-2 col-2">26.000 kr</div>
      <div class="col-md-2 col-2">32.000 kr</div>
      <div class="col-md-2 col-2">37.000 kr</div>
      <div class="col-md-2 col-2">41.000 kr</div>
    </div>
    <div class="row tafla">
      <div class="col-md-4 col-2">4 klst</div>
      <div class="col-md-2 col-2">32.000 kr</div>
      <div class="col-md-2 col-2">40.000 kr</div>
      <div class="col-md-2 col-2">48.000 kr</div>
      <div class="col-md-2 col-2">53.000 kr</div>
    </div>
    <div class="topptexti nedri">
      Námskeið
    </div>
    <div class="tafla">
      Verð fyrir námskeið er kynnt sérstaklega þegar námskeiðið er auglýst. Best er fyrir skóla- og fyrirtækjahópa að hafa samband við okkur og óska eftir tilboði.
    </div>
  </div>
</div>
