<div class="container-fluid">
  <div class="container">
    <h1>
      Algengar spurningar
    </h1>
    <div class="spurningar">
      <div class="spurning">
        <input type="checkbox" id="spurning1" class="spurningbox" style="display:none;">
        <label for="spurning1">Eru lyftukort innifalin í gjaldinu?<i class="glyphicon glyphicon-plus ic"></i></label>
        <div id="svar1" class="svar"><hr>Lyftukort eru ekki innifalin í verðinu fyrir kennsluna. Allir 6 ára og eldri þurfa lyftukort í Bláfjöllum.</div>
      </div>
      <hr class="lina">
      <div class="spurning">
        <input type="checkbox" id="spurning2" class="spurningbox" style="display:none;">
        <label for="spurning2">Hvert mæti ég?<i class="glyphicon glyphicon-plus ic"></i></label>
        <div id="svar2" class="svar"><hr>Þegar þú bókar tíma færðu upplýsingar um það hvert á að mæta.</div>
      </div>
      <hr class="lina">
      <div class="spurning">
        <input type="checkbox" id="spurning3" class="spurningbox" style="display:none;">
        <label for="spurning3">Hvað þarf ég gera fyrir kennsluna?<i class="glyphicon glyphicon-plus ic"></i></label>
        <div id="svar3" class="svar"><hr>Nauðsynlegt er að vera með skíðabúnað sem og að vera klæddur eftir aðstæðum. Hafa verður í huga að veður er undantekningarlítið verra í Bláfjöllum en í Reykjavík.</div>
      </div>
      <hr class="lina">
      <div class="spurning">
        <input type="checkbox" id="spurning4" class="spurningbox" style="display:none;">
        <label for="spurning4">Er hægt að nota íþróttastyrki vinnustaða til að greiða fyrir kennslu?<i class="glyphicon glyphicon-plus ic"></i></label>
        <div id="svar4" class="svar"><hr>Kennsla hjá okkur fellur í flestum tilfellum undir íþrótta- og eða líkamsræktarstykri vinnustaða og stéttarfélaga. Reksturinn er skráður og við gefum út kvittanir.</div>
      </div>
      <hr class="lina">
      <div class="spurning">
        <input type="checkbox" id="spurning5" class="spurningbox" style="display:none;">
        <label for="spurning5">Hvað ef það er lokað í Bláfjöllum?<i class="glyphicon glyphicon-plus ic"></i></label>
        <div id="svar5" class="svar"><hr>Ef lokað er í Bláfjöllum vegna veðurs og þú átt bókaðan tíma þá fellur tíminn niður. Engin greiðsla er tekin fyrir tíma sem falla niður. Ef greitt hefur verið fyrirfram er kennslan endurgreidd að fullu.</div>
      </div>

    </div>
    <input type="checkbox" id="my_checkbox" style="display:none;">
  </div>
</div>
