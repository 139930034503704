<div class="container-fluid">
  <nav class="navbar navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="/">Heim<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/einkakennsla">Einkakennsla <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/namskeid">Skíðanámskeið</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/skolahopar">Skólahópar</a>
        </li>
      </ul>
      <a href="/boka" class="boka"><button type="button" class="btn btn-success boka">Bóka tíma</button></a>
    </div>
  </nav>
</div>
