import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ToppurComponent } from './toppur/toppur.component';
import { UmokkurComponent } from './umokkur/umokkur.component';
import { KennslanComponent } from './kennslan/kennslan.component';
import { VerdComponent } from './verd/verd.component';
import { UmComponent } from './um/um.component';
import { SpurningarComponent } from './spurningar/spurningar.component';
import { SambandComponent } from './samband/samband.component';
import { EinkakennslaComponent } from './einkakennsla/einkakennsla.component';
import { MainComponent } from './main/main.component';
import { NamskeidComponent } from './namskeid/namskeid.component';
import { SkolahoparComponent } from './skolahopar/skolahopar.component';
import { VedridComponent } from './vedrid/vedrid.component';
import { BokaComponent } from './boka/boka.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BokunlokidComponent } from './bokunlokid/bokunlokid.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ToppurComponent,
    UmokkurComponent,
    KennslanComponent,
    VerdComponent,
    UmComponent,
    SpurningarComponent,
    SambandComponent,
    EinkakennslaComponent,
    MainComponent,
    NamskeidComponent,
    SkolahoparComponent,
    VedridComponent,
    BokaComponent,
    BokunlokidComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'is'}
  ],
  exports: [AppRoutingModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
