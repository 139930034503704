<app-header></app-header>
<div class="container-fluid allt">
  <div class="container myndatexti">
    <h1>Skólahópar</h1>
  </div>
</div>

<div class="container">
  <div class="row mt-4">
    <div class="col-3 verd">
      Verð frá: <span class="bold">40.000 kr</span>
    </div>
    <div class="offset-6 col-3">
    </div>
  </div>

  <div class="row">
    <div class="col-12 texti mt-5">
      <p>
        Undanarin ár höfum við aðstoðað með skíðaferðir skóla og fyrirtækja
      </p>
      <p>
        Með skólahópa höfum við kennt byrjendum undirstöðuatriðin og tryggt að börnin læri rétta tækni frá byrjun. Þannig léttist álag á starfsmenn skólans og meiðslahætta minnkar. Fyrirtæki geta einnig samið við okkur um kennslu hvort heldur sem er fyrir byrjendur eða aðra. Hópkennsla er ekki bókanleg á netinu, best er að hafa samband við okkur í tölvupósti á sveinngauti@sveinngauti.is
      </p>
    </div>
    <div class="col-12 mt-5">
      <h3>Í hnotskurn</h3>
    </div>
    <div class="col-12 row mt-3">
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-calendar"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-time"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-user"></i>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-4 bold tx">
        Tímabil
      </div>
      <div class="col-4 bold tx">
        Lengd kennslu
      </div>
      <div class="col-4 bold tx">
        Fjöldi
      </div>
    </div>
    <div class="col-12 row mt-1">
      <div class="col-4 txn">
        Janúar - Apríl
      </div>
      <div class="col-4 txn">
        3 - 10 klst
      </div>
      <div class="col-4 txn">
        Allt að 100
      </div>
    </div>
    <div class="col-12 row mt-3">
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-comment"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-star"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-map-marker"></i>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-4 bold tx">
        Tungumál
      </div>
      <div class="col-4 bold tx">
        Getustig
      </div>
      <div class="col-4 bold tx">
        Staðsetning
      </div>
    </div>
    <div class="col-12 row mt-1 mb-2">
      <div class="col-4 txn">
        Íslenska, enska
      </div>
      <div class="col-4 txn">
        Byrjendur sem og lengra komnir
      </div>
      <div class="col-4 txn">
        Víða
      </div>
    </div>
  </div>
</div>
<app-samband></app-samband>
