import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verd',
  templateUrl: './verd.component.html',
  styleUrls: ['./verd.component.css']
})
export class VerdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
