<app-header  class="d-none d-md-block"></app-header>
<app-toppur></app-toppur>
<app-umokkur></app-umokkur>
<app-kennslan></app-kennslan>
<app-verd></app-verd>
<app-um></app-um>
<app-spurningar></app-spurningar>
<app-samband></app-samband>
<div class="fb-customerchat"
  page_id="221574844843651">
</div>
