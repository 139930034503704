<app-header class="d-none d-md-block"></app-header>
<div class="container">
  <div class="row mt-4">
    <div class="col-12">
      <h2 class="titill">Bóka kennslu</h2>
    </div>
    <form #pontun [formGroup]="bokun">
      <div class="col-12 inpbox" *ngIf="fyrsta">
        <div class="row">
          <div class="form-group col-lg-4 col-12 mt-2">
            <label class="lab" for="tegund">Veldu tegund</label>
            <select class="form-control" id="tegund" formControlName="tegund">
              <option [ngValue]=1>Gjafabréf</option>
              <!--
              <option [ngValue]=2 disabled>Einkakennsla</option>
              <option [ngValue]=3 disabled>Námskeið</option>
              -->
            </select>
            <small id="tegundHelp" class="form-text text-muted">Í augnablikinu er eingöngu hægt að kaupa gjafabréf á heimasíðunni. Til að bóka tíma er hægt að hafa samband við okkur á Facebook, senda tölvupóst á sveinngauti@sveinngauti.is eða hringja í 895-0219.</small>
          </div>
          <div class="form-group col-lg-4 col-12 mt-2">
            <label class="lab" for="tegund">Fjöldi</label>
            <select class="form-control" id="fjoldi" formControlName="fjoldi">
              <option [ngValue]=1>1</option>
              <option [ngValue]=2>2</option>
              <option [ngValue]=3>3</option>
              <option [ngValue]=4>4</option>
            </select>
            <small id="fjoldiHelp" class="form-text text-muted">Veldu fjölda í hóp.</small>
          </div>
          <div class="form-group col-lg-4 col-12 mt-2">
            <label class="lab" for="tegund">Tími</label>
            <select class="form-control" id="klst" formControlName="klst">
              <option [ngValue]=1>1 klst</option>
              <option [ngValue]=2>2 klst</option>
              <option [ngValue]=3>3 klst</option>
              <option [ngValue]=4>4 klst</option>
            </select>
            <small id="fjoldiHelp" class="form-text text-muted">Veldu fjölda klukkustunda í kennslu.</small>
          </div>
        </div>
        <div class="verdlina">
          <span class="verd">Verð: </span><span class="upphaed">{{punktar(verd)}}kr</span>
        </div>
      </div>

      <div class="col-12 inpbox annad" *ngIf="annad">
        <h2>Upplýsingar um kaupanda</h2>
          <div class="row">
            <div class="form-group col-lg-4 col-12 mt-2">
              <label class="lab" for="nafn">Fullt nafn</label>
              <input type="text" class="form-control" id="nafn" formControlName="nafn">
              <small id="nafndHelp" class="form-text text-muted"></small>
            </div>
            <div class="form-group col-lg-4 col-12 mt-2">
              <label class="lab" for="kennitala">Kennitala</label>
              <input type="number" class="form-control" id="kennitala" formControlName="kennitala">
              <small id="kennitalaHelp" class="form-text text-muted">Einungis er nauðsylegt að slá inn kennitölu ef óskað er eftir því að fá reikning í heimabanka</small>
            </div>
            <div class="form-group col-lg-4 col-12 mt-2">
              <div class="lab">Greiðslufyrirkomulag</div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="radio" name="greidsla" id="heimabanki" value="heimabanki" formControlName="greidsla">
                <label class="form-check-label" for="heimabanki">
                  Fá sendan reikning í heimabanka
                </label>
              </div>
              <!--<div class="form-check mt-2">
                <input class="form-check-input" type="radio" name="greidsla" id="cash" value="cash" formControlName="greidsla">
                <label class="form-check-label" for="cash">
                  Greiða með reiðufé á staðnum
                </label>
              </div> -->
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-4 col-12 mt-2">
              <label class="lab" for="nafn">Tölvupóstfang</label>
              <input type="email" class="form-control" id="email" formControlName="email">
              <small id="nafndHelp" class="form-text text-muted"></small>
            </div>
            <div class="form-group col-lg-4 col-12 mt-2">
              <label class="lab" for="phone">Símanúmer</label>
              <input type="number" class="form-control" id="phone" formControlName="simanr">
              <small id="simanrHelp" class="form-text text-muted"></small>
            </div>
          </div>
        </div>

        <div class="col-lg-4 offset-lg-8 col-6 offset-6">
          <button type="button" class="afram btn btn-primary btn-lg mt-4"  (click)="afram()" *ngIf="fyrsta">Áfram</button>
          <button type="button" class="afram btn btn-success btn-lg mt-4" *ngIf="annad" (click)=submit(pontun)>Kaupa gjafabréf</button>
        </div>
        <div class="col-lg-4 col-6" *ngIf="annad" >
          <button type="button" class="tilbaka btn btn-danger btn-lg mt-4" (click)="afram()">Til baka</button>
        </div>
    </form>
  </div>
</div>
