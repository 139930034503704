<div class="container-fluid">
  <h1>Upp á hvað bjóðum við?</h1>

  <div class="row box">
    <a href='/einkakennsla' class="col-md-4 col-12">
      <div>
        <div class="row stykki">
          <div class="backmynd einka"></div>
          <div class="titill">
            Einkakennsla
          </div>
          <div class="efni">
            Viltu læra hratt og örugglega? Þá er einkakennsla fyrir þig!
          </div>
          <div class="tengill">
            Lesa meira
          </div>
        </div>
      </div>
    </a>
    <a href='/namskeid'  class="col-md-4 col-12">
      <div>
        <div class="row stykki">
          <div class="backmynd namskeid"></div>
          <div class="titill">
            Skíðanámskeið
          </div>
          <div class="efni">
            Lærir þú betur í hóp? Við höldum reglulega byrjendanámskeið fyrir allan aldur. Jafnframt er hægt að bóka námskeið fyrir lengra komna.
          </div>
          <div class="tengill">
            Lesa meira
          </div>
        </div>
      </div>
    </a>
    <a href='/skolahopar'  class="col-md-4 col-12">
      <div>
        <div class="row stykki">
          <div class="backmynd hopur"></div>
          <div class="titill">
            Kennsla fyrir skóla
          </div>
          <div class="efni">
            Er skólinn eða fyrirtækið að fara í skíðaferð? Við getum hjálpað við að koma skólahópum af stað og kennt hópnámskeið fyrir fyrirtæki.
          </div>
          <div class="tengill">
            Lesa meira
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
