<app-header></app-header>
<div class="container-fluid allt">
  <div class="container myndatexti">
    <h1>Skíðanámskeið</h1>
    <h2>Ef þú vilt læra eða bæta þig hratt</h2>
  </div>
</div>

<div class="container">
  <div class="row mt-4">
    <div class="col-3 verd">
      Verð frá: <span class="bold">5.000 kr</span>
    </div>
    <div class="offset-6 col-3">
      <button type="button" class="btn btn-success boka">Bóka tíma</button>
    </div>
  </div>

  <div class="row">
    <div class="col-12 texti mt-5">
      <p>
        Við bjóðum reglulega upp á skíðanámskeið. Námskeiðin okkar eru fyrir ákveðinn aldur og getustig.
      </p>
      <p>
        Á námskeiðum hjá okkur eru 6 - 10 manns í hóp. Yfirleitt ná námskeiðin yfir eina kvöldstund, en þó verða haldin byrjendanámskeið þar sem kennt er í þremur lotum. Námskeiðin eru almennt haldin í Bláfjöllum, með þeim möguleika að halda byrjendanámskeið í lyftunum í Reykjavík. Einnig er möguleiki á að halda námskeið á landsbyggðinni ef nægur áhugi er til staðar.
      </p>
    </div>
    <div class="col-12 mt-5">
      <h3>Í hnotskurn</h3>
    </div>
    <div class="col-12 row mt-3">
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-calendar"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-time"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-user"></i>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-4 bold tx">
        Tímabil
      </div>
      <div class="col-4 bold tx">
        Lengd kennslu
      </div>
      <div class="col-4 bold tx">
        Fjöldi í hóp
      </div>
    </div>
    <div class="col-12 row mt-1">
      <div class="col-4 txn">
        Janúar - Apríl
      </div>
      <div class="col-4 txn">
        3 - 10 klst
      </div>
      <div class="col-4 txn">
        6 - 10
      </div>
    </div>
    <div class="col-12 row mt-3">
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-comment"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-star"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-map-marker"></i>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-4 bold tx">
        Tungumál
      </div>
      <div class="col-4 bold tx">
        Getustig
      </div>
      <div class="col-4 bold tx">
        Staðsetning
      </div>
    </div>
    <div class="col-12 row mt-1 mb-2">
      <div class="col-4 txn">
        Íslenska, enska
      </div>
      <div class="col-4 txn">
        Byrjendur sem og lengra komnir
      </div>
      <div class="col-4 txn">
        Oftast Bláfjöll
      </div>
    </div>
  </div>
</div>
<app-samband></app-samband>
