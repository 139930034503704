import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vedrid',
  templateUrl: './vedrid.component.html',
  styleUrls: ['./vedrid.component.css']
})
export class VedridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
