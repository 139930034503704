import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EinkakennslaComponent} from './einkakennsla/einkakennsla.component'
import {NamskeidComponent} from './namskeid/namskeid.component'
import {SkolahoparComponent} from './skolahopar/skolahopar.component'
import {MainComponent} from './main/main.component'
import {BokaComponent} from './boka/boka.component'
import { BokunlokidComponent } from './bokunlokid/bokunlokid.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'einkakennsla', component: EinkakennslaComponent },
  { path: 'namskeid', component: NamskeidComponent },
  { path: 'skolahopar', component: SkolahoparComponent },
  { path: 'boka', component: BokaComponent },
  { path: 'bokunlokid', component: BokunlokidComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
