import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-namskeid',
  templateUrl: './namskeid.component.html',
  styleUrls: ['./namskeid.component.css']
})
export class NamskeidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
