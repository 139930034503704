<div class="container">
  <h1>Kennarar</h1>
  <div class="row">
    <div class="col-lg-3 col-6 vinstrimynd">
    </div>
    <div class="col-lg-9 col-6">
      <div class="nafn">
        Oddný Helga
      </div>
      <div class="texti">
        Oddný er reynslumikill skíðakennari. Hún fór í skíðakennsluskóla í Austurríki 2014 og vann við skíðakennslu þar veturinn 2014 - 2015. Síðan þá hefur hún kennt alla vetur á skíði bæði á Íslandi og í Austurríki. Oddný er jafnframt grunnskólakennari og er það hennar aðalstarf.
      </div>
    </div>
    <div class="col-lg-3 col-6 vinstrimynd2">
    </div>
    <div class="col-lg-9 col-6">
      <div class="nafn">
        Sveinn Gauti
      </div>
      <div class="texti">
        Sveinn Gauti er búinn að kenna lengi á skíði. Hann kennir mikið alla vetur á Íslandi. Sveinn lærði skíðakennslu í Austurríki árið 2008 og starfaði þar við kennslu 2009. Hann er einnig verkfræðingur að mennt og vinnur við það öllu jöfnu.
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-3 nidritexti">
    Þegar mikið er að gera höfum við fengið aðstoð frá öðrum kennurum. Við leggjum mikla áherslu á að allir sem kenna fyrir okkur hafi hlotið viðeigandi menntun og hafi reynslu af skíðakennslu.
  </div>
</div>
