<div class="container-fluid umokkur pt-3 pb-3">
  <div class="container texti">
    <div class="row">
      <div class="col-lg-8 col-12">
        <h1>Skíðakennslan</h1>
      </div>
      <div class="col-lg-8 col-12">
        <p>Lærðu á skíði með hjálp fagfólks. Við höfum boðoð upp á skíðakennslu í Bláfjöllum frá því 2015 með góðum árangri. Allir okkar kennarar hafa lært skíðakennslu erlendis og hafa mikla reynslu af skíðakennslu bæði erlendis og hérlendis.</p>
        <p>Við kennum mest í Bláfjöllum. Þar eru aðstæður frábærar fyrir þá sem hyggjast læra á skíði. Á svæðinu er færiband og nokkrar byrjendalyftur fyrir þá sem eru ð stíga sín fyrstu skref. Þrjár stólalyftur, fjöldi diskalyfta og allar tegundir af brekkum eru í boði fyrir lengra komna. Helsti kosturinn við að læra á skíði í Bláfjöllum er snjórinn. Færið er yfirleitt mjög gott, betra en víðast hvar erlendis. Á móti kemur að oft koma langir kaflar þar sem veðrið er vont og skíðasvæðið er lokað.</p>
        <p>Við hlökkum til að taka á móti ykkur í skíðakennslu í vetur!</p>
      </div>
      <div class="col-lg-4 col-12">
        <div class="fb-page" data-href="https://www.facebook.com/Sk%C3%AD%C3%B0akennsla-Oddn%C3%BDjar-og-Sveins-Gauta-221574844843651" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Sk%C3%AD%C3%B0akennsla-Oddn%C3%BDjar-og-Sveins-Gauta-221574844843651" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Sk%C3%AD%C3%B0akennsla-Oddn%C3%BDjar-og-Sveins-Gauta-221574844843651">Skíðakennsla Oddnýjar og Sveins Gauta</a></blockquote></div>
      </div>
    </div>
  </div>
</div>
