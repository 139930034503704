<app-header  class="d-none d-md-block"></app-header>
<div class="container-fluid allt">
  <div class="container myndatexti">
    <h1>Einkakennsla</h1>
    <h2>Ef þú vilt læra eða bæta þig hratt</h2>
  </div>
</div>

<div class="container">
  <div class="row mt-4">
    <div class="col-lg-3 col-6 verd">
      Verð frá: <span class="bold">8.000 kr/klst</span>
    </div>
    <div class="offset-lg-6 col-lg-3 col-6">
      <a href="/boka"><button type="button" class="btn btn-success boka">Bóka tíma</button></a>
    </div>
  </div>

  <div class="row">
    <div class="col-12 texti mt-5">
      <p>
        Í einkatíma er athyglin á þér og þínum þörfum til að tryggja að miklar framfarir náist á skömmum tíma.
      </p>
      <p>
        Undanfarin ár hefur einkakennsla verið mjög vinsæl hjá okkur. Við tökum að okkur að kenna börnum frá þriggja ára aldri sem og fullorðnum. Byrjendur læra hjá okkur fyrstu skrefin í færibandinu eða byrjendalyftunum á meðan þeir lengra komnu get fengið tækniæfingar í stólalyftunum eða brattari brekkunum í Suðurgili. Ef snjóalög leyfa er einnig hægt að fá kennslu í skíðun utan brauta.
      </p>
    </div>
    <div class="col-12 mt-5">
      <h3>Í hnotskurn</h3>
    </div>
    <div class="col-12 row mt-3">
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-calendar"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-time"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-user"></i>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-4 bold tx">
        Tímabil
      </div>
      <div class="col-4 bold tx">
        Lengd kennslu
      </div>
      <div class="col-4 bold tx">
        Fjöldi í hóp
      </div>
    </div>
    <div class="col-12 row mt-1">
      <div class="col-4 txn">
        Desember - Maí
      </div>
      <div class="col-4 txn">
        1 - 4 klst
      </div>
      <div class="col-4 txn">
        1 - 5
      </div>
    </div>
    <div class="col-12 row mt-3">
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-comment"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-star"></i>
      </div>
      <div class="col-4 ic">
        <i class="glyphicon glyphicon-map-marker"></i>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-4 bold tx">
        Tungumál
      </div>
      <div class="col-4 bold tx">
        Getustig
      </div>
      <div class="col-4 bold tx">
        Staðsetning
      </div>
    </div>
    <div class="col-12 row mt-1 mb-2">
      <div class="col-4 txn">
        Íslenska, enska
      </div>
      <div class="col-4 txn">
        Byrjendur sem og lengra komnir
      </div>
      <div class="col-4 txn">
        Bláfjöll, eða umsemjanlegt
      </div>
    </div>
  </div>
</div>
<app-samband></app-samband>
