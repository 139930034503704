import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

declare var require: any

@Component({
  selector: 'app-boka',
  templateUrl: './boka.component.html',
  styleUrls: ['./boka.component.css']
})
export class BokaComponent implements OnInit {

  constructor(private router: Router) { }
  fyrsta = true;
  annad = false;
  verd = 0;
  bokun:FormGroup;

  uppfaeraVerd() {
    if (this.bokun.value.tegund == 1) {
      if (this.bokun.value.fjoldi == 1) {
        if (this.bokun.value.klst == 1) {
          this.verd = 9000
        }
        else if (this.bokun.value.klst == 2) {
          this.verd = 18000
        }
        else if (this.bokun.value.klst == 3) {
          this.verd = 26000
        }
        else if (this.bokun.value.klst == 4) {
          this.verd = 32000
        }
      }
      if (this.bokun.value.fjoldi == 2) {
        if (this.bokun.value.klst == 1) {
          this.verd = 11000
        }
        else if (this.bokun.value.klst == 2) {
          this.verd = 22000
        }
        else if (this.bokun.value.klst == 3) {
          this.verd = 32000
        }
        else if (this.bokun.value.klst == 4) {
          this.verd = 40000
        }
      }
      if (this.bokun.value.fjoldi == 3) {
        if (this.bokun.value.klst == 1) {
          this.verd = 12500
        }
        else if (this.bokun.value.klst == 2) {
          this.verd = 25000
        }
        else if (this.bokun.value.klst == 3) {
          this.verd = 37000
        }
        else if (this.bokun.value.klst == 4) {
          this.verd = 48000
        }
      }
      if (this.bokun.value.fjoldi == 4) {
        if (this.bokun.value.klst == 1) {
          this.verd = 14000
        }
        else if (this.bokun.value.klst == 2) {
          this.verd = 28000
        }
        else if (this.bokun.value.klst == 3) {
          this.verd = 41000
        }
        else if (this.bokun.value.klst == 4) {
          this.verd = 53000
        }
      }
    }
  }

  punktar(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

  onChanges(): void {
    this.bokun.valueChanges.subscribe(val => {
      this.uppfaeraVerd()
    });
  }

  ngOnInit(): void {
    this.bokun = new FormGroup({
      'tegund': new FormControl(1),
      'fjoldi': new FormControl(1),
      'klst': new FormControl(1),
      'kennitala': new FormControl(),
      'simanr': new FormControl(),
      'email': new FormControl(),
      'nafn': new FormControl(),
      'greidsla': new FormControl(),
    })
    this.onChanges()
    this.uppfaeraVerd()
  }

  afram() {
    this.fyrsta = !this.fyrsta
    this.annad = !this.annad
  }

  submit(event) {
    let tegund = 'Gjafabréf'
    if (this.bokun.value.tegund == 1) {
      tegund = 'Einkakennsla'
    }
    if (this.bokun.value.tegund == 2) {
      tegund = 'Námskeið'
    }
    let fjoldi = this.bokun.value.fjoldi
    let klst = this.bokun.value.klst
    let kennitala = this.bokun.value.kennitala
    let simanr = this.bokun.value.simanr
    let email = this.bokun.value.email
    let nafn = this.bokun.value.nafn
    let greidsla = this.bokun.value.greidsla

    let texti = nafn + ' keypti gjafabréf fyrir ' + fjoldi + ' í '  + klst + ' klst. Upplýsingar: kennitala - ' + kennitala + ' símanúmer - ' + simanr + ' tölvupóstfang - ' + email + ' greiðslumáti - ' + greidsla

    var XDomainRequest;
    var Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };
    Email.send({
      Host : "smtp.elasticemail.com",
      Username : "aukakennari@aukakennari.is",
      Password : "D03FDFA0846B1B81A0AF1FB76EB012EBBBE6",
      To : 'sveinngauti@sveinngauti.is',
      From : 'aukakennari@aukakennari.is',
      Subject : 'Skíðakennsla - gjafabréf',
      Body : texti
    }).then(
      (message) => {
        this.router.navigate(['/bokunlokid']);
    },
    (error) => {
        alert('Villa kom upp. Vinsamlegast hafið samband í tölvupósti á sveinngauti@sveinngauti.is')
    })
  }

}
